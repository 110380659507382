// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//import 'bootstrap'
import 'jquery-nice-select/js/jquery.nice-select';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel';
global.toastr = require("toastr")
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
// = norequire jquery
// = norequire jquery_ujs
// = require nested_form_fields
import "jquery"
import "packs/custom"
import "packs/listings_i_posted"
import "packs/notifications"
import "packs/sales_history"
import "packs/purchase_history"
import "packs/my_viewed_listings"
import "packs/my_eyed_listings"
import "packs/dashboard_sidebar"
import "packs/categories"
// import 'select2/dist/css/select2.css'
// import "select2"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(function() {
  if (!(window.location.pathname.includes("/search_list") || window.location.pathname.includes("/products/") || window.location.pathname.includes("/my_dashboard_listings_I_posted") || window.location.pathname.includes("/search") || window.location.pathname.includes("/services") || window.location.pathname.includes("/my_dashboard_what_im_eyeing") || window.location.pathname.includes("/filter_my_viewed_listings") || window.location.pathname.includes("/my_dashboard_recently_viewed") || window.location.pathname.includes("/mark_user_recently_viewed_listing") || window.location.pathname.includes("/my_dashboard_view_chat_without_offer") || window.location.pathname.includes("/filter_recommended_listings") || window.location.pathname.includes("/my_dashboard_recommended_listings"))){
    localStorage.removeItem('scrollPosition');
    localStorage.removeItem('show_pane_recommended');
    localStorage.removeItem('show_pane');
    localStorage.removeItem('show_pane_eye');
  }
});
