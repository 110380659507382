  let sidebar = document.querySelector(".sidebar");
  let sidebarBtn = document.querySelector("#menu-id");
  let arrowbtn = document.querySelector("#arrow-link");
  let barIcon = document.querySelector("#barsBtn");
  let barsBtn = document.querySelector(".bars_icon");
  let hideheader = document.querySelector(".logo-details");
  let profileGreeting = document.querySelector(".profile_greeting");
  let wrapper = document.querySelector("#content-wrapper");
  let togglebtn = document.querySelector("#toggleBtn");
  let linksId = document.querySelector("#links-id");
  let hover_effect = document.querySelectorAll(".same-class");
  let primaryImageContainer = document.querySelector(".myclass-For-SearchView");
  let mdView_image = document.querySelector(".myclass-For-mdSearchView");
  
  window.onSideBarClose = function() {
    sidebar.classList.add("close");
    arrowbtn.classList.remove("collapse_icons");
    barIcon.classList.remove("collapse_icons");
    sidebarBtn.classList.add("bx-menu");
    sidebarBtn.classList.remove("remove-btn");
    barsBtn.classList.remove("collapse_bars_icon");
    hideheader.classList.add("hide");
    profileGreeting.classList.add("hide");
    wrapper.classList.remove("content-wrapper-open");
    wrapper.classList.add("content-wrapper-close");
    
    let username_width = document.querySelectorAll(".onClose-sidebar");
    username_width.forEach(adjust_width =>{
      adjust_width.classList.add('listingOwner-name');
      adjust_width.classList.remove('closeSidebar-listingOwner-name')
    });
   
    hover_effect.forEach(hover => {
      hover.classList.remove('notify_icon');
      hover.classList.remove('hover_effect');
      hover.classList.remove('active');
    });
  }

  window.onSideBarOpen = function() { 
    sidebar.classList.remove("close");
    arrowbtn.classList.add("collapse_icons");
    barIcon.classList.add("collapse_icons");
    barsBtn.classList.add("collapse_bars_icon");
    sidebarBtn.classList.remove("bx-menu");
    sidebarBtn.classList.add("remove-btn");
    hideheader.classList.remove("hide");
    profileGreeting.classList.remove("hide");
    wrapper.classList.add("content-wrapper-open");
    wrapper.classList.remove("content-wrapper-close");
    togglebtn.classList.remove("toggle-icons");
    
    let username_width = document.querySelectorAll(".onClose-sidebar");
    username_width.forEach(adjust_width =>{
      adjust_width.classList.remove('listingOwner-name');
      adjust_width.classList.add('closeSidebar-listingOwner-name')
    });
  
    hover_effect.forEach(hover => {
      hover.classList.add('notify_icon')
      hover.classList.add('hover_effect');
    });
  }
  

  if(sidebarBtn){
    sidebarBtn.addEventListener("click", (e) => {
      e.preventDefault();
      onSideBarClose();      
    });
  }

  if(arrowbtn){
    arrowbtn.addEventListener("click", (event) => {
      event.preventDefault();
      onSideBarOpen();
    });
  }

  