function productCategoryChange() {
  $('#product_sub_category_id').empty();
  $("#realState").prop('checked',false);

  let link = $('#product').val();
  let catId = $('#product_category').val();
    $.ajax({
      url: '/get_category',
      type: 'GET',
      dataType: 'json',
      data: {
          number: catId,
      }
  }).done(function(res) {
    var selOpts ="";
      for(i=0; i<res.length; i++){
        var subcategory_name = res[i]['category_name'];
        var subcategory_id = res[i]['id'];
        selOpts += "<option value='"+subcategory_id+"'>"+subcategory_name+"</option>";
        }
        $('#product_sub_category_id').append(selOpts);
  });
}

function productSubCategoryChange(){
    $("#realState").prop('checked',false);
    //  $('#continue-btn-div').addClass('hidden');
    let link = $('#product').val();
    let catId = $('#product_category').val();
    let sub_cat_Id = $('#product_sub_category_id').val();
    link = link.replace(/(\?category_id=\d+)/g, '');
    if (catId.length > 0) {
    $('#product').val(link + '?category_id=' + catId)
    $('#continue-btn-div').find('a').attr('href', link + '?category_id=' + catId + '&product_sub_category_id=' + sub_cat_Id)
  }
  if(catId != '' && sub_cat_Id != ''){
    $('#continue-btn-div').removeClass('hidden')
  }
}
