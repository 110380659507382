$(document).on('turbolinks:load', function(){

    $('body').on("change", '.my_viewed_sort_options', function(e){
      
      if(e.originalEvent){
        var target_div = '';
        target_div = this.dataset.targetDiv;
        var sort_by = $(selectorHook('#my_viewed_sort_options', target_div)).val();
        var per_page = $(selectorHook('.my_viewed_per_page_options', target_div)).val();
        var listing_type = this.dataset.listingType;
        var view_type = $("#view_type").text();
        
        if (this.dataset.targetDiv) {
          $('.' + this.dataset.targetDiv +' > '+'.spinner1').removeClass('d-none');
        }
        
        var data = {sort_by: sort_by, listing_type: listing_type, target_div: target_div, per_page: per_page, view: view_type };
        
        ajaxCallCustom(data, '/filter_my_viewed_listings');
      }
    });

    $(document).ready(function(){
      
      var data = {};   
      ajaxCallCustom(data, '/filter_my_viewed_listings');
    });

    $('body').on("change", '.my_viewed_per_page_options', function(e){
      
      if(e.originalEvent){
        var target_div = '';
        target_div = this.dataset.targetDiv;
        var sort_by = $(selectorHook('#my_viewed_sort_options', target_div)).val();
        var per_page = $(selectorHook('.my_viewed_per_page_options', target_div)).val();
        var listing_type = this.dataset.listingType;
        var view_type = $("#view_type").text();

        
        if (this.dataset.targetDiv) {
          $('.' + this.dataset.targetDiv +' > '+'.spinner1').removeClass('d-none');
        }
        
        var data = {sort_by: sort_by, listing_type: listing_type, target_div: target_div, per_page: per_page, view: view_type};
        
        ajaxCallCustom(data, '/filter_my_viewed_listings');
      }
    });

    function ajaxCallCustom(data, target_url){
      
      $.ajax({
        type: "GET",
        dataType: "script",
        url: target_url,
        data: data,
        success: function(){
          // initSliderMediumView()
          // initSliderMiniView();
          // initSliderLargeView();
        }
      });
    }

    function selectorHook(selector, target_div){
      if (target_div) {
        return '.' + target_div +' > '+ selector
      }
      else
        return selector
    }
})
