$(document).on('turbolinks:load', function(){

    // I have no idea what this was meant to do, needs to be revisited and removed
    // for a more targeted approach if it is necessary, feels like a bandaid.
    if (window.history && window.history.pushState) {
      $(window).on('popstate', function(e) {


        // Necessary to prevent the Chat page from refreshing when the keyword search button is clicked
        // The keyword search button DOES already have a disable turbolinks and I didn't see any code that
        // affects manipulating the history, but somewhere something must be hooked in and triggering it.
        // I just can't find what it is based on code in the file or commit history.
        if(window.location.pathname == "/my_dashboard_chat_messages") {
          return false;
        }
        else {
          window.location.reload();
        }

      });
    }

    $('#search-keyword, #search-keyword-products, #search-keyword-services').on("click", function(e){
      if(e.originalEvent){
        var target_div = ''
        target_div = this.dataset.targetDiv;
        var keyword  = $(selectorHook('.keyword-option', target_div)).val();
        var listing_type = $(selectorHook('.product-listing-type', target_div)).val();
        var category = $(selectorHook('.categories', target_div)).val();
        var sub_category = $(selectorHook('.sub_categories', target_div)).val();
        var pricing_type  = $(selectorHook('.pricing_options', target_div)).val();
        var min_price = $(selectorHook(".min-price", target_div)).val();
        var max_price = $(selectorHook(".max-price", target_div)).val();
        var product_condition  = $(selectorHook('.condition', target_div)).val();
        var other  = $(selectorHook('.other_options', target_div)).val();
        var product_message  = $(selectorHook('.message_options', target_div)).val();
        var sort_by  = $(selectorHook('.sort_options', target_div)).val();
        var per_page  = $(selectorHook('.item_per_page_search', target_div)).val();
        var distance = $(selectorHook('.location_options', target_div)).val();
        var longitude = $("#longitude").val();
        var latitude = $("#latitude").val();
        var view_type = $("#view_type").text();
        if (this.dataset.targetDiv) {
          $('.' + target_div +' > '+'.spinner1').removeClass('d-none');
        }
        else{
          $('.main-search-filter > .spinner1').removeClass('d-none');
        }
        var data = {keyword: keyword, product_message: product_message, other: other, product_condition: product_condition, pricing_type: pricing_type, min_price: min_price, max_price: max_price, sub_category: sub_category, category: category, listing_type: listing_type, target_div: target_div, sort_by: sort_by, per_page: per_page, distance: distance, longitude: longitude, latitude: latitude, view: view_type };
        add_or_remove_search("keyword-option", keyword, target_div);
        if (target_div) {
          ajaxCallCustom(data, '/filter_recommended_listings');
        }
        else
          ajaxCall(data);
      }
    });

    $('body').on("change", '.product-listing-type', function(e){
      
      if(e.originalEvent){
        var target_div = '';
        target_div = this.dataset.targetDiv;
        var keyword  = $(selectorHook('.keyword-option', target_div)).val();
        var listing_type = $(selectorHook('.product-listing-type', target_div)).val();
        if (!target_div){
          change_price_options(listing_type, target_div);
        }
        else if (!target_div && (listing_type == "wanted_products" || listing_type == "wanted_services"))
        {
          if (listing_type == "wanted_products")
            change_price_options("products", target_div);
          else
            change_price_options("services", target_div);
        }
        var category = $(selectorHook('.categories', target_div)).val();
        var sub_category = $(selectorHook('.sub_categories', target_div)).val();
        var pricing_type  = $(selectorHook('.pricing_options', target_div)).val();
        var min_price = $(selectorHook(".min-price", target_div)).val();
        var max_price = $(selectorHook(".max-price", target_div)).val();
        var product_condition  = $(selectorHook('.condition', target_div)).val();
        var other  = $(selectorHook('.other_options', target_div)).val();
        var product_message  = $(selectorHook('.message_options', target_div)).val();
        var sort_by  = $(selectorHook('.sort_options', target_div)).val();
        var per_page  = $(selectorHook('.item_per_page_search', target_div)).val();
        var distance = $(selectorHook('.location_options', target_div)).val();
        var longitude = $("#longitude").val();
        var latitude = $("#latitude").val();
        var view_type = $("#view_type").text();
        if (this.dataset.targetDiv) {
          $('.' + this.dataset.targetDiv +' > '+'.spinner1').removeClass('d-none');
        }
        else{
          $('.main-search-filter > .spinner1').removeClass('d-none');
        }

        var data = {listing_type: listing_type, keyword: keyword, product_message: product_message, other: other, product_condition: product_condition, pricing_type: pricing_type, min_price: min_price, max_price: max_price, target_div: target_div, sort_by: sort_by, per_page: per_page, distance: distance, longitude: longitude, latitude: latitude, view: view_type };
        if(listing_type == "services"){
          $('.sub-categories-main').addClass("hidden");
        }else{
          $('.sub-categories-main').removeClass("hidden");
        }
        add_or_remove_search("product-listing-type", listing_type, target_div);
         if (target_div) {
          ajaxCallCustom(data, '/filter_recommended_listings');
        }
        else
          ajaxCall(data);

        if(listing_type=="No Filter" || listing_type==""){
          $('.search-filters').html('');
        }else{
          $('.search-filters').html("<h2><b>" + listing_type.replace(/\_/g, " ").capitalizeWords() + "</b></h2>")
        }
      }
    });

    $('body').on("DOMSubtreeModified", '#select2-location_options_id-container', function(e){
      if(e.originalEvent){
        var target_div = '';
        target_div = this.dataset.targetDiv;
        var keyword  = $(selectorHook('.keyword-option', target_div)).val();
        var listing_type = $(selectorHook('.product-listing-type', target_div)).val();
        var category = $(selectorHook('.categories', target_div)).val();
        var sub_category = $(selectorHook('.sub_categories', target_div)).val();
        var pricing_type  = $(selectorHook('.pricing_options', target_div)).val();
        var min_price = $(selectorHook(".min-price", target_div)).val();
        var max_price = $(selectorHook(".max-price", target_div)).val();
        var product_condition  = $(selectorHook('.condition', target_div)).val();
        var other  = $(selectorHook('.other_options', target_div)).val();
        var product_message  = $(selectorHook('.message_options', target_div)).val();
        var current_user = $(this).attr('current_user');
        var sort_by  = $(selectorHook('.sort_options', target_div)).val();
        var per_page  = $(selectorHook('.item_per_page_search', target_div)).val();
        var distance = $(selectorHook('.location_options', target_div)).val();
        var longitude = $("#longitude").val();
        var latitude = $("#latitude").val();
        var view_type = $("#view_type").text();
        if (this.dataset.targetDiv) {
          $('.' + this.dataset.targetDiv +' > '+'.spinner1').removeClass('d-none');
        }
        else{
          $('.main-search-filter > .spinner1').removeClass('d-none');
        }
        var data = {current_user: current_user, product_message: product_message, other: other, product_condition: product_condition, pricing_type: pricing_type, min_price: min_price, max_price: max_price, sub_category: sub_category, category: category, listing_type: listing_type, keyword: keyword, target_div: target_div, sort_by: sort_by, per_page: per_page, distance: distance, longitude: longitude, latitude: latitude, view: view_type};
        add_or_remove_search("location_options", distance+' mile', target_div);
        if (target_div) {
          ajaxCallCustom(data, '/filter_recommended_listings');
        }
        else
          ajaxCall(data);
      }
    });



    $('body').on("change", '.product-listing-type-generic', function(e){
      if(e.originalEvent){
        var listing_type  = $(this).val();
        var data = {listing_type: listing_type};
        ajaxCallCustom(data, "/filter_listing_categories");
      }
    });

    $('body').on("change", '.product-category-generic', function(e){
      if(e.originalEvent){
        var category  = $(this).val();
        var data = {category: category};
        ajaxCallCustom(data, "/filter_category_listing");
      }
    });

    $('body').on("change", '.product-listing-type-generic-nav', function(e){
     localStorage.setItem("ProductCategory", $('.product-category-generic-nav').val());
      localStorage.setItem("ProductListingType", $('.product-listing-type-generic-nav').val());
    var listingValue =   $('.product-listing-type-generic-nav').val();
      if(e.originalEvent){
        var nav = true
        var listing_type  = $(this).val();
        var data = {listing_type: listing_type, nav: nav};
        ajaxCallCustom(data, "/filter_listing_categories");
      }
    });

    $('body').on("change", '.product-category-generic-nav', function(e){
     localStorage.setItem("ProductCategory", $('.product-category-generic-nav').val());
    localStorage.setItem("ProductListingType", $('.product-listing-type-generic-nav').val());
      var productCategory = $('.product-category-generic-nav').val();
      if(e.originalEvent){
        var nav = true
        var category  = $(this).val();
        var data = {category: category, nav: nav};
        ajaxCallCustom(data, "/filter_category_listing");
      }
    });


    $('body').on("change", '.item_per_page_search', function(e){
     e.preventDefault();
      if(e.originalEvent){
        var target_div = '';
        target_div = this.dataset.targetDiv;
        var keyword  = $(selectorHook('.keyword-option', target_div)).val();
        var listing_type = $(selectorHook('.product-listing-type', target_div)).val();
        if (!target_div){
          change_price_options(listing_type, target_div);
        }
        else if (!target_div && (listing_type == "wanted_products" || listing_type == "wanted_services"))
        {
          if (listing_type == "wanted_products")
            change_price_options("products", target_div);
          else
            change_price_options("services", target_div);
        }
        var category = $(selectorHook('.categories', target_div)).val();
        var sub_category = $(selectorHook('.sub_categories', target_div)).val();
        var pricing_type  = $(selectorHook('.pricing_options', target_div)).val();
        var min_price = $(selectorHook(".min-price", target_div)).val();
        var max_price = $(selectorHook(".max-price", target_div)).val();
        var product_condition  = $(selectorHook('.condition', target_div)).val();
        var other  = $(selectorHook('.other_options', target_div)).val();
        var product_message  = $(selectorHook('.message_options', target_div)).val();
        var sort_by  = $(selectorHook('.sort_options', target_div)).val();
        var per_page  = $(selectorHook('.item_per_page_search', target_div)).val();
        var distance = $(selectorHook('.location_options', target_div)).val();
        var longitude = $("#longitude").val();
        var latitude = $("#latitude").val();
        var view_type = $("#view_type").text();
        if (this.dataset.targetDiv) {
          $('.' + this.dataset.targetDiv +' > '+'.spinner1').removeClass('d-none');
        }
        else{
          $('.main-search-filter > .spinner1').removeClass('d-none');
        }
        
        var data = {listing_type: listing_type, keyword: keyword, product_message: product_message, other: other, product_condition: product_condition, pricing_type: pricing_type, min_price: min_price, max_price: max_price, target_div: target_div, sort_by: sort_by, per_page: per_page, sub_category: sub_category, category: category, distance: distance, longitude: longitude, latitude: latitude, view: view_type };
        if(listing_type == "services"){
          $('.sub-categories-main').addClass("hidden");
        }else{
          $('.sub-categories-main').removeClass("hidden");
        }
        add_or_remove_search("product-listing-type", listing_type, target_div);
         if (target_div) {
          ajaxCallCustom(data, '/filter_recommended_listings');
        }
        else
          ajaxCall(data);

        if(listing_type=="No Filter" || listing_type==""){
          $('.search-filters').html('');
        }else{
          $('.search-filters').html("<h2><b>" + listing_type.replace(/\_/g, " ").capitalizeWords() + "</b></h2>")
        }
      }
    });

    $('body').on("click", '.change-view-type', function(e){
      e.preventDefault();
      var ChangeViewType = $(this).attr('data-view-type');

      const $selectMiniViewMode = $("#select-mini-view-mode"),
        $selectLargeViewMode = $("#select-large-view-mode");

      if (ChangeViewType == 'mini') {
        $selectMiniViewMode.find('img').attr('src', $selectMiniViewMode.data('blue-icon'));
        $selectLargeViewMode.find('img').attr('src', $selectLargeViewMode.data('black-icon'));
      } else {
        $selectMiniViewMode.find('img').attr('src', $selectMiniViewMode.data('black-icon'));
        $selectLargeViewMode.find('img').attr('src', $selectLargeViewMode.data('blue-icon'));
      }

      $("#view_type").text(ChangeViewType);

       if(e.originalEvent){
         var target_div = '';
         target_div = this.dataset.targetDiv;
         var keyword  = $(selectorHook('.keyword-option', target_div)).val();
         var listing_type = $(selectorHook('.product-listing-type', target_div)).val();
         if (!target_div){
           change_price_options(listing_type, target_div);
         }
         else if (!target_div && (listing_type == "wanted_products" || listing_type == "wanted_services"))
         {
           if (listing_type == "wanted_products")
             change_price_options("products", target_div);
           else
             change_price_options("services", target_div);
         }
         var category = $(selectorHook('.categories', target_div)).val();
         var sub_category = $(selectorHook('.sub_categories', target_div)).val();
         var pricing_type  = $(selectorHook('.pricing_options', target_div)).val();
         var min_price = $(selectorHook(".min-price", target_div)).val();
         var max_price = $(selectorHook(".max-price", target_div)).val();
         var product_condition  = $(selectorHook('.condition', target_div)).val();
         var other  = $(selectorHook('.other_options', target_div)).val();
         var product_message  = $(selectorHook('.message_options', target_div)).val();
         var sort_by  = $(selectorHook('.sort_options', target_div)).val();
         var per_page  = $(selectorHook('.item_per_page_search', target_div)).val();
         var distance = $(selectorHook('.location_options', target_div)).val();
         var longitude = $("#longitude").val();
         var latitude = $("#latitude").val();
         var view_type = $("#view_type").text();
         
         if (this.dataset.targetDiv) {
           $('.' + this.dataset.targetDiv +' > '+'.spinner1').removeClass('d-none');
         }
         else{
           $('.main-search-filter > .spinner1').removeClass('d-none');
         }
         
         var data = {listing_type: listing_type, keyword: keyword, product_message: product_message, other: other, product_condition: product_condition, pricing_type: pricing_type, min_price: min_price, max_price: max_price, target_div: target_div, sort_by: sort_by, per_page: per_page, sub_category: sub_category, category: category, distance: distance, longitude: longitude, latitude: latitude, view: view_type };
         if(listing_type == "services"){
           $('.sub-categories-main').addClass("hidden");
         }else{
           $('.sub-categories-main').removeClass("hidden");
         }
         add_or_remove_search("product-listing-type", listing_type, target_div);
          if (target_div) {
           ajaxCallCustom(data, '/filter_recommended_listings');
         }
         else
           ajaxCall(data);
 
         if(listing_type=="No Filter" || listing_type==""){
           $('.search-filters').html('');
         }else{
           $('.search-filters').html("<h2><b>" + listing_type.replace(/\_/g, " ").capitalizeWords() + "</b></h2>")
         }
       }
       ajaxCall(data)
    });
    $('body').on("click", '.change-profile-view-type', function(e){
      e.preventDefault();
      var ChangeProfileViewType = $(this).attr('data-view-type');
      let Id = $(this).attr("id");
      $("#view_type").text(ChangeProfileViewType);
      $("#user_id").val(Id);

       if(e.originalEvent){
         var target_div = '';
         target_div = this.dataset.targetDiv;
         var keyword  = $(selectorHook('.keyword-option', target_div)).val();
         var listing_type = $(selectorHook('.product-listing-type', target_div)).val();
         if (!target_div){
           change_price_options(listing_type, target_div);
         }
         else if (!target_div && (listing_type == "wanted_products" || listing_type == "wanted_services"))
         {
           if (listing_type == "wanted_products")
             change_price_options("products", target_div);
           else
             change_price_options("services", target_div);
         }
         var category = $(selectorHook('.categories', target_div)).val();
         var sub_category = $(selectorHook('.sub_categories', target_div)).val();
         var pricing_type  = $(selectorHook('.pricing_options', target_div)).val();
         var min_price = $(selectorHook(".min-price", target_div)).val();
         var max_price = $(selectorHook(".max-price", target_div)).val();
         var product_condition  = $(selectorHook('.condition', target_div)).val();
         var other  = $(selectorHook('.other_options', target_div)).val();
         var product_message  = $(selectorHook('.message_options', target_div)).val();
         var sort_by  = $(selectorHook('.sort_options', target_div)).val();
         var per_page  = $(selectorHook('.item_per_page_search', target_div)).val();
         var distance = $(selectorHook('.location_options', target_div)).val();
         var longitude = $("#longitude").val();
         var latitude = $("#latitude").val();
         var view_type = $("#view_type").text();
         var id = $("#user_id").val();
         
         if (this.dataset.targetDiv) {
           $('.' + this.dataset.targetDiv +' > '+'.spinner1').removeClass('d-none');
         }
         else{
           $('.main-search-filter > .spinner1').removeClass('d-none');
         }
         
         var data = {listing_type: listing_type, keyword: keyword, product_message: product_message, other: other, product_condition: product_condition, pricing_type: pricing_type, min_price: min_price, max_price: max_price, target_div: target_div, sort_by: sort_by, per_page: per_page, sub_category: sub_category, category: category, distance: distance, longitude: longitude, latitude: latitude, view: view_type, id: id };
         if(listing_type == "services"){
           $('.sub-categories-main').addClass("hidden");
         }else{
           $('.sub-categories-main').removeClass("hidden");
         }

       }
       ajaxCall1(data)
    });


    $('body').on("change", '.pricing_options', function(e){
      if(e.originalEvent){
        var target_div = '';
        target_div = this.dataset.targetDiv;
        var keyword  = $(selectorHook('.keyword-option', target_div)).val();
        var listing_type = $(selectorHook('.product-listing-type', target_div)).val();
        var category = $(selectorHook('.categories', target_div)).val();
        var sub_category = $(selectorHook('.sub_categories', target_div)).val();
        var pricing_type  = $(this).val();
        var min_price = $(selectorHook(".min-price", target_div)).val();
        var max_price = $(selectorHook(".max-price", target_div)).val();
        var product_condition  = $(selectorHook('.condition', target_div)).val();
        var other  = $(selectorHook('.other_options', target_div)).val();
        var product_message  = $(selectorHook('.message_options', target_div)).val();
        var sort_by  = $(selectorHook('.sort_options', target_div)).val();
        var per_page  = $(selectorHook('.item_per_page_search', target_div)).val();
        var distance = $(selectorHook('.location_options', target_div)).val();
        var longitude = $("#longitude").val();
        var latitude = $("#latitude").val();
        var view_type = $("#view_type").text();
        if (this.dataset.targetDiv) {
          $('.' + this.dataset.targetDiv +' > '+'.spinner1').removeClass('d-none');
        }
        else{
          $('.main-search-filter > .spinner1').removeClass('d-none');
        }

        var data = {product_message: product_message, other: other, product_condition: product_condition, pricing_type: pricing_type, min_price: min_price, max_price: max_price, sub_category: sub_category, category: category, listing_type: listing_type, keyword: keyword, target_div: target_div, sort_by: sort_by, per_page: per_page, distance: distance, longitude: longitude, latitude: latitude, view: view_type };
        add_or_remove_search("pricing_options", pricing_type, target_div);
         if (target_div) {
          ajaxCallCustom(data, '/filter_recommended_listings');
        }
        else
          ajaxCall(data);
      }
    });

    $('body').on("change", '.condition', function(e){
      if(e.originalEvent){
        var target_div = '';
        target_div = this.dataset.targetDiv;
        var keyword  = $(selectorHook('.keyword-option', target_div)).val();
        var listing_type = $(selectorHook('.product-listing-type', target_div)).val();
        var category = $(selectorHook('.categories', target_div)).val();
        var sub_category = $(selectorHook('.sub_categories', target_div)).val();
        var pricing_type  = $(selectorHook('.pricing_options', target_div)).val();
        var min_price = $(selectorHook(".min-price", target_div)).val();
        var max_price = $(selectorHook(".max-price", target_div)).val();
        var product_condition  = $(selectorHook('.condition', target_div)).val();
        var other  = $(selectorHook('.other_options', target_div)).val();
        var product_message  = $(selectorHook('.message_options', target_div)).val();
        var sort_by  = $(selectorHook('.sort_options', target_div)).val();
        var per_page  = $(selectorHook('.item_per_page_search', target_div)).val();
        var distance = $(selectorHook('.location_options', target_div)).val();
        var longitude = $("#longitude").val();
        var latitude = $("#latitude").val();
        var view_type = $("#view_type").text();
        if (this.dataset.targetDiv) {
          $('.' + this.dataset.targetDiv +' > '+'.spinner1').removeClass('d-none');
        }
        else{
          $('.main-search-filter > .spinner1').removeClass('d-none');
        }
        var data = {product_message: product_message, other: other, product_condition: product_condition, pricing_type: pricing_type, min_price: min_price, max_price: max_price, sub_category: sub_category, category: category, listing_type: listing_type, keyword: keyword, target_div: target_div, sort_by: sort_by, per_page: per_page, distance: distance, longitude: longitude, latitude: latitude, view: view_type };
        add_or_remove_search("condition", product_condition, target_div);
         if (target_div) {
          ajaxCallCustom(data, '/filter_recommended_listings');
        }
        else
          ajaxCall(data);
      }
    });


    $('body').on("change", '.other_options', function(e){
      if(e.originalEvent){
        var target_div = '';
        target_div = this.dataset.targetDiv;
        var keyword  = $(selectorHook('.keyword-option', target_div)).val();
        var listing_type = $(selectorHook('.product-listing-type', target_div)).val();
        var category = $(selectorHook('.categories', target_div)).val();
        var sub_category = $(selectorHook('.sub_categories', target_div)).val();
        var pricing_type  = $(selectorHook('.pricing_options', target_div)).val();
        var min_price = $(selectorHook(".min-price", target_div)).val();
        var max_price = $(selectorHook(".max-price", target_div)).val();
        var product_condition  = $(selectorHook('.condition', target_div)).val();
        var other  = $(selectorHook('.other_options', target_div)).val();
        var product_message  = $(selectorHook('.message_options', target_div)).val();
        var current_user = $(this).attr('current_user');
        var sort_by  = $(selectorHook('.sort_options', target_div)).val();
        var per_page  = $(selectorHook('.item_per_page_search', target_div)).val();
        var distance = $(selectorHook('.location_options', target_div)).val();
        var longitude = $("#longitude").val();
        var latitude = $("#latitude").val();
        var view_type = $("#view_type").text();
        if (this.dataset.targetDiv) {
          $('.' + this.dataset.targetDiv +' > '+'.spinner1').removeClass('d-none');
        }
        else{
          $('.main-search-filter > .spinner1').removeClass('d-none');
        }
        var data = {current_user: current_user, product_message: product_message, other: other, product_condition: product_condition, pricing_type: pricing_type, min_price: min_price, max_price: max_price, sub_category: sub_category, category: category, listing_type: listing_type, keyword: keyword, target_div: target_div, sort_by: sort_by, per_page: per_page, distance: distance, longitude: longitude, latitude: latitude, view: view_type };
        add_or_remove_search("other_options", other, target_div);
        if (target_div) {
          ajaxCallCustom(data, '/filter_recommended_listings');
        }
        else
          ajaxCall(data);
      }
    });

    $('body').on("change", '.message_options', function(e){
      if(e.originalEvent){
        var target_div = '';
        target_div = this.dataset.targetDiv;
        var keyword  = $(selectorHook('.keyword-option', target_div)).val();
        var listing_type = $(selectorHook('.product-listing-type', target_div)).val();
        var category = $(selectorHook('.categories', target_div)).val();
        var sub_category = $(selectorHook('.sub_categories', target_div)).val();
        var pricing_type  = $(selectorHook('.pricing_options', target_div)).val();
        var min_price = $(selectorHook(".min-price", target_div)).val();
        var max_price = $(selectorHook(".max-price", target_div)).val();
        var product_condition  = $(selectorHook('.condition', target_div)).val();
        var other  = $(selectorHook('.other_options', target_div)).val();
        var product_message  = $(selectorHook('.message_options', target_div)).val();
        var sort_by  = $(selectorHook('.sort_options', target_div)).val();
        var per_page  = $(selectorHook('.item_per_page_search', target_div)).val();
        var current_user = $(this).attr('current_user');
        var distance = $(selectorHook('.location_options', target_div)).val();
        var longitude = $("#longitude").val();
        var latitude = $("#latitude").val();
        var view_type = $('#view_type').text();
        if (this.dataset.targetDiv) {
          $('.' + this.dataset.targetDiv +' > '+'.spinner1').removeClass('d-none');
        }
        else{
          $('.main-search-filter > .spinner1').removeClass('d-none');
        }
        var data = {per_page: per_page, product_message: product_message, other: other, product_condition: product_condition, pricing_type: pricing_type, min_price: min_price, max_price: max_price, sub_category: sub_category, category: category, listing_type: listing_type, keyword: keyword, current_user: current_user, target_div: target_div, sort_by: sort_by, distance: distance, longitude: longitude, latitude: latitude, view: view_type };
        add_or_remove_search("message_options", product_message, target_div);
        if (target_div) {
          ajaxCallCustom(data, '/filter_recommended_listings');
        }
        else
          ajaxCall(data);
      }
    });

    $('body').on("change", '.sort_options', function(e){
      if(e.originalEvent){
        var target_div = '';
        target_div = this.dataset.targetDiv;
        var keyword  = $(selectorHook('.keyword-option', target_div)).val();
        var listing_type = $(selectorHook('.product-listing-type', target_div)).val();
        var category = $(selectorHook('.categories', target_div)).val();
        var sub_category = $(selectorHook('.sub_categories', target_div)).val();
        var pricing_type  = $(selectorHook('.pricing_options', target_div)).val();
        var min_price = $(selectorHook(".min-price", target_div)).val();
        var max_price = $(selectorHook(".max-price", target_div)).val();
        var product_condition  = $(selectorHook('.condition', target_div)).val();
        var other  = $(selectorHook('.other_options', target_div)).val();
        var product_message  = $(selectorHook('.message_options', target_div)).val();
        var sort_by  = $(selectorHook('.sort_options', target_div)).val();
        var per_page  = $(selectorHook('.item_per_page_search', target_div)).val();
        var current_user = $(this).attr('current_user');
        var distance = $(selectorHook('.location_options', target_div)).val();
        var longitude = $("#longitude").val();
        var latitude = $("#latitude").val();
        var view_type = $('#view_type').text();
        if (this.dataset.targetDiv) {
          $('.' + this.dataset.targetDiv +' > '+'.spinner1').removeClass('d-none');
        }
        else{
          $('.main-search-filter > .spinner1').removeClass('d-none');
        }
        var data = {per_page: per_page, product_message: product_message, sort_by: sort_by, other: other, product_condition: product_condition, pricing_type: pricing_type, min_price: min_price, max_price: max_price, sub_category: sub_category, category: category, listing_type: listing_type, keyword: keyword, current_user: current_user, target_div: target_div, distance: distance, longitude: longitude, latitude: latitude, view :view_type };
        add_or_remove_search("sort_options", $(this).find("option:selected").text(), target_div);
        if (target_div) {
          ajaxCallCustom(data, '/filter_recommended_listings');
        }
        else
          ajaxCall(data);
      }
    });

    $('body').on("click", '#price-search', function(e){
      if(e.originalEvent){
        var target_div = '';
        target_div = this.dataset.targetDiv;
        var keyword  = $(selectorHook('.keyword-option', target_div)).val();
        var listing_type = $(selectorHook('.product-listing-type', target_div)).val();
        var category = $(selectorHook('.categories', target_div)).val();
        var sub_category = $(selectorHook('.sub_categories', target_div)).val();
        var pricing_type  = $(selectorHook('.pricing_options', target_div)).val();
        var min_price = $(selectorHook(".min-price", target_div)).val();
        var max_price = $(selectorHook(".max-price", target_div)).val();
        var product_condition  = $(selectorHook('.condition', target_div)).val();
        var other  = $(selectorHook('.other_options', target_div)).val();
        var product_message  = $(selectorHook('.message_options', target_div)).val();
        var sort_by  = $(selectorHook('.sort_options', target_div)).val();
        var per_page  = $(selectorHook('.item_per_page_search', target_div)).val();
        var distance = $(selectorHook('.location_options', target_div)).val();
        var longitude = $("#longitude").val();
        var latitude = $("#latitude").val();
        var view_type = $("#view_type").text();

        if (this.dataset.targetDiv) {
          $('.' + this.dataset.targetDiv +' > '+'.spinner1').removeClass('d-none');
        }
        else{
          $('.main-search-filter > .spinner1').removeClass('d-none');
        }
        var data = {per_page: per_page, min_price: min_price, max_price: max_price,sub_category: sub_category, category: category, listing_type: listing_type, keyword: keyword, product_message: product_message, other: other, product_condition: product_condition, pricing_type: pricing_type, target_div: target_div, sort_by: sort_by, distance: distance, longitude: longitude, latitude: latitude, view: view_type };
        add_or_remove_search("min-price", min_price, target_div );
        add_or_remove_search("max-price", max_price, target_div );
        if (target_div) {
          ajaxCallCustom(data, '/filter_recommended_listings');
        }
        else
          ajaxCall(data);
      }
    });

    

    $('body').on('click', '#reset-filters', function () {
      reset_all_filters();
      var target_div = '';
      target_div = this.dataset.targetDiv;
      var view_type = $('#view_type').text();
      var per_page  = $(selectorHook('.item_per_page_search', target_div)).val();
      var data = {per_page: per_page, view: view_type };
      ajaxCall(data);
    });

    $('body').on('click', '.reset-recommended-filters', function () {
      var target_div = '';
      target_div = this.dataset.targetDiv;
      reset_all_filters_generic(this.dataset.targetDiv);
      var view_type = $('#view_type').text();
      var per_page  = $(selectorHook('.item_per_page_search', target_div)).val();
      var data = {per_page: per_page, view: view_type };
      if (this.dataset.targetDiv.includes('products'))
        var data = {target_div: this.dataset.targetDiv, listing_type: 'products', reset_filter: 'true', per_page: per_page, view: view_type};
      else
        var data = {target_div: this.dataset.targetDiv, listing_type: 'services', reset_filter: 'true', per_page: per_page, view: view_type};
      ajaxCallCustom(data, '/filter_recommended_listings');
    });

    $('body').on('click', '#remove', function(){
      _id = '.' + $(this).parent().attr("id");
      if (this.dataset.targetDiv)
        $('.'+this.dataset.targetDiv + ' '+_id).val(null).change();
      else
        $(_id).val(null).change();
      $(this).parent().remove();
      var target_div = '';
      target_div = this.dataset.targetDiv;
      if (_id == '.product-listing-type') {
        $(selectorHook('.search-filters', target_div)).html("");
        $(selectorHook('.categories', target_div)).remove();
        $(selectorHook('.sub_categories', target_div)).remove();
        $(selectorHook('#all-filter-values', target_div)).find('#sub_categories').remove();
        var listing = $(selectorHook('.product-listing-type', target_div)).val();
        if (!target_div){
          $(selectorHook('#all-filter-values', target_div)).find('#pricing_options').remove();
          change_price_options("products", target_div)
        }
        else if (!target_div && (listing == "wanted_products" || listing == "wanted_services"))
        {
          if (listing == "wanted_products")
            change_price_options("products", target_div);
          else
            change_price_options("services", target_div);
        }
        $(selectorHook('#all-filter-values', target_div)).find('#categories').remove();
      }

      if (_id == '.product-listing-type' && $(this).parent().find('span').text() == "Services"){
        $('.sub-categories-main').removeClass("hidden");
      }

      if (_id == '.categories') {
        $(selectorHook('#select2-categories_dropdown-container', target_div)).html("No Filter")
        $(selectorHook('.sub_categories', target_div)).remove();
        $(selectorHook('.categories', target_div)).remove();
        $(selectorHook('#all-filter-values', target_div)).find('#sub_categories').remove();
      }
      if (_id == '.sub_categories') {$(selectorHook('#select2-sub_categories_dropdown-container', target_div)).html("No Filter")}

      var keyword  = $(selectorHook('.keyword-option', target_div)).val();
      var listing_type = $(selectorHook('.product-listing-type', target_div)).val();
      var category = $(selectorHook('.categories', target_div)).val();
      var sub_category = $(selectorHook('.sub_categories', target_div)).val();
      var pricing_type  = $(selectorHook('.pricing_options', target_div)).val();
      var min_price = $(selectorHook(".min-price", target_div)).val();
      var max_price = $(selectorHook(".max-price", target_div)).val();
      var product_condition  = $(selectorHook('.condition', target_div)).val();
      var other  = $(selectorHook('.other_options', target_div)).val();
      var product_message  = $(selectorHook('.message_options', target_div)).val();
      var remove_btn = _id == '.product-listing-type' ? true : false;
      var sort_by  = $(selectorHook('.sort_options', target_div)).val();
      var per_page  = $(selectorHook('.item_per_page_search', target_div)).val();
      var distance = $(selectorHook('.location_options', target_div)).val();
      var longitude = $("#longitude").val();
      var latitude = $("#latitude").val();
      var view_type = $("#view_type").text();
      if (this.dataset.targetDiv)
        $('.' + this.dataset.targetDiv +' > '+'.spinner1').removeClass('d-none');
      else
        $('.main-search-filter > .spinner1').removeClass('d-none');

      if (!listing_type && target_div.length > 0) {
        if (target_div.includes('products'))
          listing_type = 'products';
        else if (target_div.includes('services'))
          listing_type = 'services';
      }

      var data = {per_page: per_page, product_message: product_message, other: other, product_condition: product_condition, pricing_type: pricing_type, min_price: min_price, max_price: max_price, sub_category: sub_category, category: category, listing_type: listing_type, keyword: keyword, remove_btn: remove_btn, target_div: target_div, sort_by: sort_by, distance: distance, longitude: longitude, latitude: latitude, view: view_type };
      if (target_div) {
        ajaxCallCustom(data, '/filter_recommended_listings');
      }
      else
        ajaxCall(data);
    })


    $('body').on('DOMSubtreeModified','#select2-product_category-container', function(e){
      if ($('#product_sub_category_id').attr("parent_changed") != 'true') {
        $.ajax({
          type: "GET",
          dataType: "json",
          url: "/sub_categories_related_categories",
          data: {category_id: $('#product_category').val()},
          success: function(data){
            if(data.ids.includes(parseInt($('#product_sub_category_id').val())) && data.status){
              options = "<option></option>"
              $.each(data.categories,function(key, value){
                options += "<option value="+ value.id + ">" + value.category_name + "</option>"
              });
              var selected_opt = $('#product_sub_category_id').val();
              $('#product_sub_category_id').html(options);
              $('#product_sub_category_id').val(selected_opt);

              if ($('#product_category').val() != '' && $('#product_sub_category_id').val() != '') {
                $('#continue-btn-div').removeClass('hidden');
              } else {
                // $('#continue-btn-div').addClass('hidden');
              }
            }
            else if (!data.ids.includes(parseInt($('#product_sub_category_id').val())) || !data.status) {
              options = "<option></option>"
              $.each(data.categories,function(key, value){
                options += "<option value="+ value.id + ">" + value.category_name + "</option>"
              });
              $('#product_sub_category_id').find('option').remove();
              $('#product_sub_category_id').append(options)

              if ($('#product_category').val() != '' && $('#product_sub_category_id').val() != '') {
                $('#continue-btn-div').removeClass('hidden');
              } else {
                // $('#continue-btn-div').addClass('hidden');
              }
            }
          }
        });
      }
      $('#product_sub_category_id').attr("parent_changed", false)
    });

    $('body').on('DOMSubtreeModified','#select2-product_sub_category_id-container', function(){
      $.ajax({
        type: "GET",
        dataType: "script",
        url: "/category_from_sub_category",
        data: {sub_category_id: $('#product_sub_category_id').val()}
      });
    });

    function ajaxCall(data){
      $.ajax({
        type: "GET",
        dataType: "script",
        url: "/search",
        data: data,
        success: function(){
          // initSliderMediumView()
          // initSliderMiniView();
          // initSliderLargeView();
        }
      });
    }

    function ajaxCall1(data){
      console.log(data)
      id = data.id;
      $.ajax({
        type: "GET",
        dataType: "script",
        url: `/user_info/${id}`,
        data: data,
        success: function(){
         // initSliderMediumView()
         // initSliderMiniView();
         // initSliderLargeView();
        }
      });
    }

    function ajaxCallCustom(data, target_url){
      $.ajax({
        type: "GET",
        dataType: "script",
        url: target_url,
        data: data,
        success: function(){
          // initSliderMediumView()
          // initSliderMiniView();
          // initSliderLargeView();
        }
      });
    }

    function selectorHook(selector, target_div){
      if (target_div) {
        return '.' + target_div +' > '+ selector
      }
      else
        return selector
    }
    $(document).ready(function(){
      var category = $('.categories').val();
      var sub_category = $('.sub_categories').val();
      var listing_type = $('.product-listing-type').val();
      if(category){
        let data = {listing_type: listing_type ,category: category, sub_category: sub_category, };
        ajaxCall(data);
      }
    });

    $(document).ready(function() {
      var keyword = $('.keyword-option').val()
      var listing_type = $('.product-listing-type-generic-nav').val();  
      var category = $('.product-category-generic-nav').val();
      var path = window.location.pathname;
      var productPath = document.referrer.split('/').at(-1);    
      var categoryList  = localStorage?.getItem("ProductCategory")
      var productList = localStorage?.getItem("ProductListingType")
      try {
        if(path == '/search_list'){  
          if(keyword != "" || keyword == "" && categoryList  != "" && categoryList != null || productList  != "" && productList  != null){  
            
            let data = {keyword: keyword, listing_type: productList, category: categoryList};
            ajaxCall(data);
            localStorage.removeItem("ProductListingType");
            localStorage.removeItem("ProductCategory");
          }
          else if(productPath === "" || productPath.search("offer=buyer%20send%20offer&chat_room_id=false") === 2 || productPath.search("offer=buyer%20send%20offer&chat_room_id=false") === 3 || productPath.search("search_list") === 0 || productPath.search("my_dashboard_view_chat_without_offer") === 0 || productPath.search("scroll_down=false")  === 3 || productPath.search("scroll_down=false") === 3)  {
            
          }else {
            
            reset_all_filters()
            localStorage.removeItem("ProductListingType");
            localStorage.removeItem("ProductCategory");
            let data = {};
            ajaxCall(data);
          }
       
        }
      } catch(e) {
        console.log("Error",e)
      }     
    });

    function reset_all_filters(){
      var all_ids = ".keyword-option, .product-listing-type, .categories, .sub_categories, .pricing_options, .message_options, .other_options, .condition, .min-price, .max-price, .sort_options"
      $(all_ids).val(null).trigger('reset')
      $('#all-filter-values').html('');  
    }

    function reset_all_filters_generic(target){
      var all_ids = `${'.'+target} .keyword-option, ${'.'+target} .product-listing-type, ${'.'+target} .categories, ${'.'+target} .sub_categories, ${'.'+target} .pricing_options, ${'.'+target}
      .message_options, ${'.'+target} .other_options, ${'.'+target} .condition, ${'.'+target} .min-price, ${'.'+target} .max-price, ${'.'+target} .sort_options`
      $(all_ids).val(null).change();
      if (target.includes('products'))
        $(`${'.'+target} .product-listing-type`).val('products').change();
      else
        $(`${'.'+target} .product-listing-type`).val('services').change();

      $('.'+target+' #all-filter-values').html('');
    }

    function add_or_remove_search(_id, text, parent_div = ''){
      var value = "<span class='single-filter-value btn btn-secondary m-1' id=" + _id + "><span>" + text.charAt(0).toUpperCase() + text.slice(1).replace(/\_/g, " ") + "</span><i class='fa fa-times-circle px-1' id='remove' data-target-div='"+ parent_div +"'></i></span>"
      if (parent_div && parent_div.length > 0)
        var remove_able_div = $("."+parent_div+" > "+"#all-filter-values").find('#' + _id);
      else
        var remove_able_div = $("#all-filter-values").find('#' + _id);

      if (_id == "min-price" || _id == "max-price") {
        value = "<span class='single-filter-value btn btn-secondary m-1' id=" + _id + "><span>" + _id + ":" + text + "</span><i class='fa fa-times-circle px-1' id='remove' data-target-div='"+ parent_div +"'></i></span>"
      }

      if (remove_able_div.length == 1) {
        remove_able_div.remove();
      }

      if (_id == "product-listing-type"){
        if (text != '') {
          value = "<span class='single-filter-value btn btn-secondary m-1' id=" + _id + "><span>" + text.replace(/\_/g, " ").capitalizeWords() + "</span><i class='fa fa-times-circle px-1' id='remove' data-target-div='"+ parent_div +"'></i></span>"
        }
        $('#categories').remove();
        $('#sub_categories').remove();
      }

      if (text != "" && text != 'No Filter' && text != 'No Sort') {
        if (parent_div) {
          $("."+parent_div+" > "+"#all-filter-values").append(value);
        }
        else
          $("#all-filter-values").append(value);
      }

    }

    function change_price_options(listing_type, target_div){
      options = "<option value='' >No Filter</option>"

      if (listing_type == "services") {
        var data = {'Commission': 'commission', 'Flat Rate': 'flat_rate', 'Hourly Rate': 'hourly_rate' }
        $.each(data,function(key, value){
          options += "<option value="+ value + ">" + key + "</option>"
        });

        $(selectorHook('.pricing_options', target_div)).find('option').remove();
        $(selectorHook('.pricing_options', target_div)).append(options);
        var remove_able_div = $(selectorHook("#all-filter-values", target_div)).find("#pricing_options");

        if (remove_able_div.length == 1)
          remove_able_div.remove();
      }
      else if (listing_type == "products" || listing_type == "wanted_listing" || listing_type == "") {
        var data = {'Firm Price': 'firm_price', 'Accepts Offers': 'accepts_offer'}
        $.each(data,function(key, value){
          options += "<option value="+ value + ">" + key + "</option>"
        });
        $(selectorHook('.pricing_options', target_div)).find('option').remove();
        $(selectorHook('.pricing_options', target_div)).append(options);
        var remove_able_div = $(selectorHook("#all-filter-values", target_div)).find("#pricing_options");
        if (remove_able_div.length == 1)
          remove_able_div.remove();
      }
    }

    String.prototype.capitalizeWords = function() {
      return this.split(" ").map(function(ele){ return ele[0].toUpperCase() + ele.slice(1).toLowerCase();}).join(" ");
    };


  // BLOCK BLOCKING USER USERS

  // For elements on the page at render time
  $(document).on('click', '.report-menu-toggle', function(event){
    show_report_menu($(this));
  })

  // For elements coming in via AJAX requests
  // $('.listing-list').on('click', '.report-menu-toggle', function(event){
  //   show_report_menu($(this));
  // })

  $('.listing-list').on('click', '.send-user-btn-wl', function() {
    $(this).addClass('loading');
  })

  function show_report_menu(clicked_el) {
    // $(".dropdown-menu").removeClass("show");
    $(".dropdown-menu").removeClass("d-block");
    // $(".report-menu-toggle").removeClass("show");
    // clicked_el.toggleClass('show');
    // clicked_el.next('.dropdown-menu').toggleClass('show');
    clicked_el.next('.dropdown-menu').toggleClass('d-block');
    // event.stopPropagation();
  }

  $('body').on("click", function(event){
    $(".dropdown-menu").removeClass("d-block");
    // $(".dropdown-menu").removeClass("show");
    // $(".report-menu-toggle").removeClass("show");
  })

// fuctions to handle cross browsers css
function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent.toLowerCase());
}

function toggleSafariClass(className) {
  const elements = $(`.${className}`);

  elements.each(function() {
    if (isSafari()) {
      $(this).addClass('safari');
    }
  });
}

$( document ).ready(function() {
  toggleSafariClass('payment-row');
  toggleSafariClass('wanted-product-row');
});

// function to handle qty buttons
function isValidNumberInput(input) {
  const inputValue = input.value.trim();
  const numbersOnly = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
  input.value = numbersOnly;
}

$(document).ready(function() {
  $('.quantity-field').on('input', function() {
    isValidNumberInput(this);
  });
});

})

$(document).ready(function() {
  $('body').on('blur click', 'input.precision-2[type=number]', function() {
    let updated = parseFloat(this.value).toFixed(2);
    $(this).val(updated);
  })
})
