$(document).on('turbolinks:load', function(){

    $('body').on("change", '.my-sales-listings, .sales-pricing-type-opt, .my_sale_sort_options', function(e){
      if(e.originalEvent){
        var target_div = '';
        var listing_name = $(selectorHook('#my-sales-listings', target_div)).val();
        var pricing_type = $(selectorHook('#sales-pricing-type-opt', target_div)).val();
        var sort_by = $(selectorHook('#my_sale_sort_options', target_div)).val();
        
        $('.my-sales-filter .loader').removeClass('d-none');
        
        var data = {pricing_type: pricing_type, listing_name: listing_name, sort_by: sort_by};
        
        add_or_remove_search($(this).attr("id"), $(this).find("option:selected").text(), 'my-sales-filter');
        ajaxCallCustom(data, '/filter_my_sales');
      }
    });

    $('body').on('click', '#remove-my-sales-filter', function(){
      if (this.dataset.targetDiv)
        $('#'+this.dataset.targetDiv ).val(null).change();

      $(this).parent().remove();

      var target_div = '';
      var listing_name = $(selectorHook('#my-sales-listings', target_div)).val();
      var pricing_type = $(selectorHook('#sales-pricing-type-opt', target_div)).val();
      var sort_by = $(selectorHook('#my_sale_sort_options', target_div)).val();

      $('.my-sales-filter .loader').removeClass('d-none');

      var data = {pricing_type: pricing_type, listing_name: listing_name, sort_by: sort_by};
      ajaxCallCustom(data, '/filter_my_sales');
    })



    function ajaxCallCustom(data, target_url){
      $.ajax({
        type: "GET",
        dataType: "script",
        url: target_url,
        data: data
      });
    }

    function selectorHook(selector, target_div){
      if (target_div) {
        return '.' + target_div +' > '+ selector
      }
      else
        return selector
    }


    function add_or_remove_search(_id, text, parent_div = ''){
      var value = "<span class='single-filter-value' id=" + "capsule-" + _id + "><button id='remove-my-sales-filter' data-target-div='"+ _id +"'>x</button><span>" + text.charAt(0).toUpperCase() + text.slice(1).replace(/\_/g, " ") + "</span></span>"
      var remove_able_div = $("#all-filter-values").find('#' + "capsule-" + _id);

      if (remove_able_div.length == 1) {
        remove_able_div.remove();
      }

      if (text != "" && text != 'No Filter' && text != 'No Sort') {
        if (parent_div) {
          $("."+parent_div+" > "+"#all-filter-values").append(value);
        }
        else
          $("#all-filter-values").append(value);
      }

    }

    String.prototype.capitalizeWords = function() {
      return this.split(" ").map(function(ele){ return ele[0].toUpperCase() + ele.slice(1).toLowerCase();}).join(" ");
    };
})
