$(document).on('turbolinks:load', function(){

  $('body').on("change", '#notification-listings', function(e){
    if(e.originalEvent){
      var listing_id = $(this).val();
      var sort_by = $('.notification_sort_options').val();
      
      $('.my-notifications-filter .loader').removeClass('d-none');
      
      var data = {listing_id: listing_id, sort_by: sort_by};
      
      ajaxCallCustom(data, '/filter_notifications');
    }
  });

  $('body').on("change", '.notification_sort_options', function(e){
    if(e.originalEvent){
      var listing_id = $('#notification-listings').val();
      var sort_by = $(this).val();
      
      $('.my-notifications-filter .loader').removeClass('d-none');
      
      var data = {listing_id: listing_id, sort_by: sort_by};
      
      ajaxCallCustom(data, '/filter_notifications');
    }
  });

  function ajaxCallCustom(data, target_url){
    $.ajax({
      type: "GET",
      dataType: "script",
      url: target_url,
      data: data
    });
  }
})
