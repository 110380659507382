import consumer from "./consumer"

$( document ).ready(function() {
  console.log('Creating Presence Subscriber');
  createSubscriber();
});

function createSubscriber() {

  consumer.subscriptions.create({channel: "PresenceChannel"}, {
    connected() {
      // Called when the subscription is ready for use on the server
      console.log("Connected to the PresenceChannel!");
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
      $('#server-connection-lost').show();
    },
    received(data) {
      console.log('Someone came online or went offline!')
      let current_user_id = $('#current-user').data('current-user-id')
      if (current_user_id == data['senderId']) {
        // Don't do anything about your own presence
      } else {
        // Do something about someone else's presence
        // $('#append_new_message').append(data['htmlReceiver']);
      }
    }
  })
}
