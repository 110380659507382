$(document).on('turbolinks:load', function(){

    $('body').on("change", '.my-product-listing-type, .my-offer-message, .my-listings, .my_listings_sort_options', function(e){
      if(e.originalEvent){
        var target_div = '';
        var listing_type = $(selectorHook('#my-product-listing-type', target_div)).val();
        var offer_and_message = $(selectorHook('#offer-message-opt', target_div)).val();
        var listing_id = $(selectorHook('#my-listings', target_div)).val();
        var sort_by = $(selectorHook('#my_listings_sort_options', target_div)).val();      
        $('.my-listings-filter .spinner1').removeClass('d-none');

        // var data = {listing_type: listing_type, listing_name: listing_name, offer_and_message: offer_and_message, sort_by: sort_by};
        // $('.my-listings-filter .loader').removeClass('d-none');
        var data = {listing_type: listing_type, listing_id: listing_id, offer_and_message: offer_and_message, sort_by: sort_by};

        add_or_remove_search($(this).attr("id"), $(this).find("option:selected").text(), 'my-listings-filter');

        if (offer_and_message == "")
          $(selectorHook('#offer-message-opt', target_div)).prop("selectedIndex", 0);
        
        if (listing_type == "")
          $(selectorHook('#my-product-listing-type', target_div)).prop("selectedIndex", 0);

        if (listing_id == "")
          $(selectorHook('#my-listings', target_div)).prop("selectedIndex", 0);

        ajaxCallCustom(data, '/filter_my_listings');
      }
    });



    // pre-filter to productId if passed  in as URL param
    const urlParams = new URLSearchParams(window.location.search);

    const productId = urlParams.get('product_id');
    if (productId) {
      const targetValue = `product-${productId}`;
      $('#my-listings').val(targetValue);
      const $option = $('#my-listings option').filter(function() {
        return $(this).val() === targetValue;
      });
      const optionText = $option.text();
      add_or_remove_search('my-listings', optionText, 'my-listings-filter');

      var data = {listing_type: '', listing_id: targetValue, offer_and_message: '', sort_by: ''};
      ajaxCallCustom(data, '/filter_my_listings');
    }




    $('body').on('click', '#remove-my-listing', function(){
      if (this.dataset.targetDiv)
        $('#'+this.dataset.targetDiv ).val(null).change();

      $(this).parent().remove();

      var target_div = '';
      var listing_type = $(selectorHook('#my-product-listing-type', target_div)).val();
      var offer_and_message = $(selectorHook('#offer-message-opt', target_div)).val();
      var listing_id = $(selectorHook('#my-listings', target_div)).val();
      var sort_by = $(selectorHook('#my_listings_sort_options', target_div)).val();
      $('.my-listings-filter .spinner1').removeClass('d-none');

      var data = {listing_type: listing_type, listing_id: listing_id, offer_and_message: offer_and_message, sort_by: sort_by};
      ajaxCallCustom(data, '/filter_my_listings');
    })



    function ajaxCallCustom(data, target_url){
      $.ajax({
        type: "GET",
        dataType: "script",
        url: target_url,
        data: data
      });
    }

    function selectorHook(selector, target_div){
      if (target_div) {
        return '.' + target_div +' > '+ selector
      }
      else
        return selector
    }


    function add_or_remove_search(_id, text, parent_div = ''){
      var value = "<span class='single-filter-value btn btn-secondary m-1' id=" + "capsule-" + _id + "><span>" + text.charAt(0).toUpperCase() + text.slice(1).replace(/\_/g, " ") + "</span><i class='fa fa-times-circle px-1' id='remove-my-listing' data-target-div='"+ _id +"'></i></span>"
      var remove_able_div = $("#all-filter-values").find('#' + "capsule-" + _id);

      if (remove_able_div.length == 1) {
        remove_able_div.remove();
      }

      if (_id == "capsule-my-product-listing-type"){
        if (text != '') {
          value = "<span class='single-filter-value btn btn-secondary m-1' id=" + "capsule-" + _id + "><span>" + text.replace(/\_/g, " ").capitalizeWords(); + "</span><i class='fa fa-times-circle px-1' id='remove-my-listing' data-target-div='"+ _id +"'></i></span>"
        }
      }

      if (text != "" && text != 'No Filter' && text != 'No Sort') {
        if (parent_div) {
          $("."+parent_div+" > "+"#all-filter-values").append(value);
        }
        else
          $("#all-filter-values").append(value);
      }

    }

    String.prototype.capitalizeWords = function() {
      return this.split(" ").map(function(ele){ return ele[0].toUpperCase() + ele.slice(1).toLowerCase();}).join(" ");
    };
})
